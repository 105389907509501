<template>
  <base-card>
    <button class="remove-booking-button-temp menu-icon" @click="toFhBooking()">
      <img
        src="@/assets/FH_logo.png"
        alt="Gå til finstadbru hundesport bookingside"
        width="200"
      />
      <h1>Booking</h1>
    </button>
  </base-card>
  <!-- TODO: make more content for frontpage -->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  components: { BaseCard },
  name: "Frontpage",
  setup() {
    const router = useRouter();

    const toFhBooking = () => {
      router.push({ path: "/fhbooking" });
    };

    return { toFhBooking };
  },
});
</script>
<style scoped>
button {
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  background-color: rgba(255, 0, 0, 0);
  border: none;
}
</style>
