const pickedDateModule = {
  state(): Record<string, unknown> {
    return {
      pickedDate: "",
    };
  },
  mutations: {
    setPickedDate(
      state: { pickedDate: string },
      payload: { pickedDate: string }
    ): void {
      state.pickedDate = payload.pickedDate;
    },
  },
  getters: {
    getPickedDate(state: { pickedDate: string }): string {
      return state.pickedDate;
    },
  },
};

export default pickedDateModule;
