import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const app = createApp(App)
  .use(Toast, {
    position: POSITION.BOTTOM_CENTER,
  })
  .use(store)
  .use(router);

const firebaseConfig = {
  apiKey: "AIzaSyDEdWPO_4HkECggJImgUIbmAUUB1p5JV_c",
  authDomain: "finstadbruhundesport.firebaseapp.com",
  projectId: "finstadbruhundesport",
  databaseURL:
    "https://finstadbruhundesport-default-rtdb.europe-west1.firebasedatabase.app/",
  storageBucket: "finstadbruhundesport.appspot.com",
  messagingSenderId: "764847612985",
  appId: "1:764847612985:web:3f9af8b9df1838273319e8",
  measurementId: "G-8S9CXJ4JD6",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.appCheck().activate("6LebfHocAAAAAL9D88_m2XXgERkas0D8sh2LTn8C", true);
firebase.appCheck().setTokenAutoRefreshEnabled(true);

app.mount("#app");
