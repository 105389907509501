class Booking {
  date: string;
  time: string;
  field: string;
  status: string;
  name: string;
  email: string;
  tlf: string;
  otherInfo: string;

  constructor(
    date: string,
    time: string,
    field: string,
    status: string,
    name: string,
    email: string,
    tlf: string,
    otherInfo: string
  ) {
    this.date = date;
    this.time = time;
    this.field = field;
    this.status = status;
    this.name = name;
    this.email = email;
    this.tlf = tlf;
    this.otherInfo = otherInfo;
  }
}

const chosenBookingsModule = {
  state() {
    const bookings: Array<Booking> = [];
    return {
      bookings,
    };
  },
  mutations: {
    addBookingOnHold(
      state: { bookings: Array<Booking> },
      payload: {
        booking: Booking;
      }
    ): void {
      state.bookings.push(payload.booking);
    },
    removeAllBookingsOnHold(state: { bookings: Array<Booking> }): void {
      state.bookings.length = 0;
    },
  },
  getters: {
    getBookingsOnHold(state: { bookings: Array<Booking> }): Array<Booking> {
      return state.bookings;
    },
  },
};

export default chosenBookingsModule;
