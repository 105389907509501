import { createStore } from "vuex";
import pickedDateModule from "./modules/pickedDate";
import chosenBookingsModule from "./modules/chosenbookings";
import bookingToShowModule from "./modules/bookingToShow";
import isModalOpenModule from "./modules/isModalOpen";
import bookingsOnHold from "./modules/bookingsOnHold";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    pickedDate: pickedDateModule,
    chosenBookings: chosenBookingsModule,
    bookingToShowModule: bookingToShowModule,
    isModalOpenModule: isModalOpenModule,
    bookingsOnHold: bookingsOnHold,
  },
});
