class Booking {
  date: string;
  time: string;
  field: string;
  status: string;
  name: string;
  tlf: string;
  email: string;
  info: string;

  constructor(
    date: string,
    time: string,
    field: string,
    status: string,
    name: string,
    email: string,
    tlf: string,
    info: string
  ) {
    this.date = date;
    this.time = time;
    this.field = field;
    this.status = status;
    this.name = name;
    this.email = email;
    this.tlf = tlf;
    this.info = info;
  }
}

const bookingToShowModule = {
  state() {
    const booking: Booking = {
      date: "",
      time: "",
      field: "",
      status: "",
      name: "",
      email: "",
      tlf: "",
      info: "",
    };
    return {
      booking,
    };
  },
  mutations: {
    newBookingToShow(
      state: { booking: Booking },
      payload: {
        date: string;
        time: string;
        field: string;
        status: string;
        name: string;
        email: string;
        tlf: string;
        info: string;
      }
    ): void {
      state.booking.date = payload.date;
      state.booking.time = payload.time;
      state.booking.field = payload.field;
      state.booking.status = payload.status;
      state.booking.name = payload.name;
      state.booking.email = payload.email;
      state.booking.tlf = payload.tlf;
      state.booking.info = payload.info;
    },
    clearBookingToShow(state: { booking: Booking }): void {
      state.booking = new Booking("", "", "", "", "", "", "", "");
    },
  },

  getters: {
    getBookingToShow(state: { booking: Booking }): Booking {
      return state.booking;
    },
  },
};

export default bookingToShowModule;
