<template>
  <div :class="{ 'less-padding': lessPadding }">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["lessPadding"],
});
</script>

<style scoped>
div {
  display: inline-block;
  margin: 0.6rem auto;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 4rem;
  background-color: #ffffff;
}
@media screen and (max-width: 991px) {
  div {
    width: 100%;
  }
  .less-padding {
    padding: 4rem 1rem;
  }
}
</style>
