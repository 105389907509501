const isModalOpenModule = {
  state(): { isModalOpen: boolean } {
    return {
      isModalOpen: false,
    };
  },
  mutations: {
    updateIsModalOpen(
      state: { isModalOpen: boolean },
      payload: { value: boolean }
    ): void {
      state.isModalOpen = payload.value;
    },
  },
  getters: {
    getIsModalOpen(state: { isModalOpen: boolean }): boolean {
      return state.isModalOpen;
    },
  },
};

export default isModalOpenModule;
