
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  components: { BaseCard },
  name: "Frontpage",
  setup() {
    const router = useRouter();

    const toFhBooking = () => {
      router.push({ path: "/fhbooking" });
    };

    return { toFhBooking };
  },
});
