
import { computed, defineComponent, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import firebase from "firebase";

export default defineComponent({
  setup() {
    console.log("v2022.1.5");

    const store = useStore();
    const router = useRouter();

    const auth = firebase.auth();
    auth.signInAnonymously();

    const isModalOpen = computed(() => {
      return store.getters.getIsModalOpen;
    });
    watch(
      () => isModalOpen.value,
      () => {
        if (isModalOpen.value) {
          window.scroll({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            document.documentElement.style.overflow = "hidden";
          }, 500);
        } else {
          document.documentElement.style.overflow = "auto";
        }
      }
    );
    router.beforeEach(() => {
      window.scroll({ top: 0, behavior: "smooth" });
    });
  },
});
