class Booking {
  date: string;
  time: string;
  field: string;

  constructor(date: string, time: string, field: string) {
    this.date = date;
    this.time = time;
    this.field = field;
  }
}

const chosenBookingsModule = {
  state() {
    const bookings: Array<Booking> = [];
    return {
      bookings,
    };
  },
  mutations: {
    addChosenBooking(
      state: { bookings: Array<Booking> },
      payload: { date: string; time: string; field: string }
    ): void {
      const pickedBooking = new Booking(
        payload.date,
        payload.time,
        payload.field
      );

      state.bookings.push(pickedBooking);
    },
    removeChosenBooking(
      state: { bookings: Array<Booking> },
      payload: { pickedBooking: Booking }
    ): void {
      for (let i = 0; i < state.bookings.length; i++) {
        if (
          state.bookings[i].date === payload.pickedBooking.date &&
          state.bookings[i].time === payload.pickedBooking.time &&
          state.bookings[i].field === payload.pickedBooking.field
        ) {
          state.bookings.splice(i, 1);
        }
      }
    },
    removeAllBookings(state: { bookings: Array<Booking> }): void {
      state.bookings.length = 0;
    },
  },
  getters: {
    getChosenBookings(state: { bookings: Array<Booking> }): Array<Booking> {
      return state.bookings;
    },
  },
};

export default chosenBookingsModule;
