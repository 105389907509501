import firebase from "firebase";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Frontpage from "../views/Frontpage.vue";
import { useToast } from "vue-toastification";
const toast = useToast();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Frontpage",
    component: Frontpage,
  },
  {
    path: "/fhbooking",
    name: "FinstadbruHundesportBooking",
    component: () => import("../views/FhBooking/Information.vue"),
  },
  {
    path: "/fhbooking/admin",
    name: "Admin",
    component: () => import("../views/FhBooking/Admin.vue"),
    beforeEnter: (to, from, next) => {
      const email = firebase.auth().currentUser?.email;
      if (email) {
        if (email === "elinholde@gmail.com" || email === "post@finstadbru.no") {
          next();
        } else {
          toast.error(
            "Bruker med epost " +
              email +
              " har dessverre ikke tilgang til admin siden"
          );
          next("/fhbooking/admin/login");
        }
      } else {
        next("/fhbooking/admin/login");
      }
    },
  },
  {
    path: "/fhbooking/admin/login",
    name: "AdminLogin",
    component: () => import("../views/FhBooking/AdminLogIn.vue"),
  },
  {
    path: "/fhbooking/booking",
    name: "Booking",
    component: () => import("../views/FhBooking/Booking.vue"),
  },
  {
    path: "/fhbooking/booking/checkout",
    name: "Checkout",
    component: () => import("../views/FhBooking/Checkout.vue"),
  },
  {
    path: "/fhbooking/booking/confirmation",
    name: "Confirmation",
    component: () => import("../views/FhBooking/Confirmation.vue"),
  },
  {
    path: "/fhbooking/booking/privacypolicy",
    name: "Privacy Policy",
    component: () => import("../views/FhBooking/PrivacyPolicy.vue"),
  },
  {
    path: "/fhbooking/booking/terms",
    name: "Terms",
    component: () => import("../views/FhBooking/Terms.vue"),
  },
  // if URL not matching any routes
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound.vue"),
  },
  {
    path: "/blank",
    name: "Blank",
    component: () => import("../views/Blank.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
